<template>
    <div class="login">
        <div class="fanhui" @click="$router.push('/')"><i class="el-icon-arrow-left"></i>返回首页</div>
        <div class="box flex">
            <img src="shexie/img/decorate.png" alt="">
            <div class="right">
                <div class="title">用 户 登 录</div>
                <div>
                    <img src="shexie/img/phone.png" alt="">
                    <span>手机号</span>
                    <input class="back" v-model="form.phone" placeholder="请输入您的手机号" width="100%" />
                </div>
                <div>
                    <img src="shexie/img/password.png" alt="">
                    <span>密码</span>
                    <input class="back" v-model="form.password" placeholder="请输入密码" width="100%" type="password" />
                    <!-- <div class="cursor" style="text-align:right;" @click="$router.push('/modify_password')">忘记密码？</div> -->
                </div>
                <div class="btn" @click="login">登录</div>
                <div class="t-center">没有账号？<span @click="$router.push('/register')">立即注册</span></div>
            </div>
            <img src="shexie/img/shan.png" alt="" class="imgs">
        </div>
    </div>
</template>

<script>
import { login } from '@/api/login'
import { users } from '@/api/login'
export default {
    data() {
        return {
            form: {}
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        //登录
        login() {
            if (!this.form.phone) {
                this.$message.warning('请输入用户名！'); return;
            } else if (!this.form.password) {
                this.$message.warning('请输入密码！'); return;
            } else {
                login(this.form).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        localStorage.setItem('sheixetoken', res.data.token)
                        users().then(res => {
                            console.log(res)
                            if (res.code == 200) {
                                localStorage.setItem('userid', res.data.id)
                                this.$router.push({ path: '/' })
                            }
                        })

                    }
                })
            }
        },
    }
};
</script>

<style scoped lang="scss">
.login {
    width: 100%;
    min-height: 100vh;
    background-image: url('/shexie/img/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 0.1px;

    .fanhui {
        width: 1200px;
        margin: 60px auto;
        cursor: pointer;
    }

    .box {
        width: 1200px;
        margin: 50px auto 0px;
        background: #FFF7F0;
        border-radius: 20px;
        box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.2);
        position: relative;

        .imgs {
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 0;
        }

        .right {
            width: 100%;
            padding: 40px 80px;
            position: relative;
            z-index: 1;

            .title {
                text-align: center;
                font-weight: bold;
                font-size: 30px;
                color: #CD0000;
                margin-bottom: 50px;
            }

            img {
                margin-right: 10px;
            }

            .btn {
                width: 340px;
                height: 48px;
                text-align: center;
                line-height: 48px;
                color: #fff;
                background: #CC0000;
                border-radius: 24px;
                cursor: pointer;
                margin: 100px auto 10px;
            }

            .t-center {
                span {
                    color: #CD0000;
                    cursor: pointer;
                }
            }

            .back {
                width: 100%;
                margin: 20px 0px;
                border-bottom: 1px solid #999;
                font-size: 20px;
                padding-bottom: 8px;
            }
        }
    }
}
</style>
