import service from '@/utils/request'

//登录
export function login(data){
    return service({
        url:'api/login',
        method:'post',
        data
    })
}
//注册
export function register(data){
    return service({
        url:'api/register',
        method:'post',
        data
    })
}
//找回密码
export function forget_password(data){
    return service({
        url:'api/forget_password',
        method:'post',
        data
    })
}
//退出登录
export function logout(){
    return service({
        url:'api/logout',
        method:'post',
    })
}


//用户信息
export function users(){
    return service({
        url:'api/member/info',
        method:'get',
    })
}

//菜单
export function menu(){
    return service({
        url:'api/menu/menu/1',
        method:'get',
    })
}
//注册无需短信认证
export function register2(data){
    return service({
        url:'api/register-no-verify',
        method:'post',
        data
    })
}